import React from 'react';
import styled from 'styled-components';

// floating action button
const FabWrapper = (props) => {
  const {
    isShown = true,
    children = null,
    onClick = () => 0,
    position = 'bottom-right',
  } = props;
  return (
    <Wrapper position={position} isShown={isShown} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 60px;
  height: 60px;
  background-color: #b79d7a;
  border-radius: 30px;
  z-index: 10;
  position: fixed;
  ${(props) =>
    props.position === 'top-right'
      ? `
  top: calc(var(--navbar-height) + 20px);
  right: 20px;
  `
      : `
  right: 20px;
  bottom: 20px;
  `}

  cursor: pointer;
  display: ${(props) => (props.isShown ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 30px 4px rgba(0, 0, 0, 0.05);
  & > .icon {
    font-size: 22px;
    color: #fff;
  }
`;

export default FabWrapper;
