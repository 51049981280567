import styled from 'styled-components';
import React from 'react';
import {CATEGORY_ALL_PRODUCTS_KEY} from '../../domain/Constants';
import {findCatInTree} from '../../utils/product';
import {
  UpOutlined as UpOutlinedIcon,
  DownOutlined as DownOutlinedIcon,
} from '@ant-design/icons';

const SHOW_ALL_CAT = false;

const SideNav = (props) => {
  const {
    visible,
    tablet = false,
    activeCat = null,
    onCatClick = () => 0,
    catTree = null,
  } = props;

  if (!catTree || !Array.isArray(catTree.children)) {
    return null;
  }

  return (
    <Wrapper tablet={tablet} visible={visible} className="sidenav">
      <div className="sticky">
        {catTree.children.map((c, cIdx) => {
          const active = !!findCatInTree(c, activeCat);
          return (
            <section className="sidenav-link-group" key={cIdx}>
              {(c.key !== CATEGORY_ALL_PRODUCTS_KEY || SHOW_ALL_CAT) && (
                <div
                  className={`link-group-title${active ? ' active' : ''}`}
                  key={cIdx}
                  onClick={() => onCatClick(c)}>
                  {c.name}
                  {active ? (
                    <UpOutlinedIcon className="icon" />
                  ) : (
                    <DownOutlinedIcon className="icon" />
                  )}
                </div>
              )}

              {c.key === CATEGORY_ALL_PRODUCTS_KEY || active
                ? c.children?.map((child, idx) => {
                    return (
                      <div
                        className={`link-group-sub-title${
                          activeCat === child.key ? ' active' : ''
                        } ${
                          active && c.key !== CATEGORY_ALL_PRODUCTS_KEY
                            ? 'indent'
                            : ''
                        }`}
                        key={idx}
                        onClick={() => onCatClick(child)}>
                        {child.name}
                      </div>
                    );
                  })
                : null}
            </section>
          );
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  background-color: #fff;
  z-index: 9;

  & .sticky {
    position: sticky;
    top: calc(var(--navbar-height) - 50px);
    padding-top: 50px;
    padding-left: 80px;
    padding-right: 140px;
  }

  .sidenav-link-group {
    & > .link-group-title,
    .link-group-sub-title {
      margin: 10px 0;
      padding: 10px 0;
      transition: color 0.1s ease-in;
    }

    & > .link-group-title {
      font-size: 20px;
      color: #595757;
      text-align: left;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      & > .icon {
        margin-left: 10px;
        font-size: 14px;
      }

      :before {
        content: '|';
        position: absolute;
        left: -12px;
        top: 8px;
        transform: scaleX(3);
        color: #595757;
      }
    }

    & > .link-group-title.active,
    & > .link-group-sub-title.active {
      color: var(--theme-primary);
    }

    & > .link-group-title:hover,
    & > .link-group-sub-title:hover {
      color: #d8bc96;
    }

    & > .link-group-sub-title {
      font-size: 14px;
      color: #595757;
      text-align: left;
      cursor: pointer;
    }

    .active {
      color: #b79d7a;
    }
    .indent {
      padding-left: 15px;
    }
  }

  ${(props) =>
    props.tablet
      ? `
  position: fixed;
  height: calc(100% - var(--navbar-height));
  transition: all 400ms ease-in-out;
  overflow-y: auto;
  transform: ${props.visible ? 'translateX(0)' : 'translateX(-100%)'};
  `
      : ''}
`;

export default SideNav;
