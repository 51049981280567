import {PRODUCT_TYPE, CATEGORY_ALL_PRODUCTS_KEY} from './Constants';
import moment from 'moment';

function getMinimumProductPrice(product) {
  if (product.spec.length === 0) {
    return 0;
  }
  let _minimumPrice = product.spec[0].price;
  for (let spec of product.spec) {
    if (spec.price < _minimumPrice) {
      _minimumPrice = spec.price;
    }
  }
  return _minimumPrice;
}

function isValidAdditionalProduct(product) {
  return product.type === PRODUCT_TYPE.additional && product.spec[0];
}

function isRecommendAdditionalProductForThisItem(product, additionalProductId) {
  return (
    product.recommend_additional_products.indexOf(additionalProductId) !== -1
  );
}

function isNormalProduct(product) {
  return (
    (product.type === PRODUCT_TYPE.default ||
      product.type === PRODUCT_TYPE.enterprise) &&
    product.spec[0]
  );
}

function isProductAllowToBuy(product) {
  // FIXME: will change field name to published_start_time / published_end_time when scheduled published feature is on live
  if (product.type === PRODUCT_TYPE.enterprise) {
    let now = moment();
    if (
      product.enterprise_published_time_start &&
      now < moment(product.enterprise_published_time_start)
    ) {
      return false;
    }
    if (
      product.enterprise_published_time_end &&
      now > moment(product.enterprise_published_time_end)
    ) {
      return false;
    }
  }

  return true;
}

function isPublishedProduct(product) {
  return !!product.published;
}

function convertCatDataToUiCat(catTree) {
  let _oneLayerCats = [];
  let _allProductsKey = CATEGORY_ALL_PRODUCTS_KEY;
  let _hasChildCats = []; // layer 2

  if (!catTree) {
    return {
      children: [],
    };
  }

  _oneLayerCats = catTree.children
    .filter((c) => !!c.published)
    .filter((c) => !c.children || c.children.length === 0);
  _hasChildCats = catTree.children
    .filter((c) => !!c.published)
    .map((c) => ({
      ...c,
      children: c.children ? c.children.filter((ch) => !!ch.published) : [],
    }))
    .filter((c) => c.children && c.children.length !== 0);

  return {
    children: [
      ..._hasChildCats,
      {
        key: _allProductsKey, // all products
        name: '全部商品',
        children: _oneLayerCats.map((c) => ({
          ...c,
          key: `${_allProductsKey}__${c.key}`,
        })),
      },
    ],
  };
}

function convertProductCatToUiCat(product, catTree) {
  // ["fake-id-01", "fake-id-02__1"] -> fake-id-01 is one layer cats, should convert to "all__fake-id-01"
  if (!catTree) {
    return product;
  }

  let _covertedCats = [];
  let _allProductsKey = CATEGORY_ALL_PRODUCTS_KEY;
  let _oneLayerCats = catTree.children
    .filter((c) => !c.children || c.children.length === 0)
    .map((c) => c.key);

  _covertedCats = [
    `${_allProductsKey}`,
    ...product.category.map((c) => {
      return _oneLayerCats.indexOf(c) !== -1 ? `${_allProductsKey}__${c}` : c;
    }),
  ];

  return {
    ...product,
    category: _covertedCats,
  };
}

export {
  getMinimumProductPrice,
  isValidAdditionalProduct,
  isRecommendAdditionalProductForThisItem,
  isNormalProduct,
  isProductAllowToBuy,
  isPublishedProduct,
  convertCatDataToUiCat,
  convertProductCatToUiCat,
};
