import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {CloseOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {navigate} from 'gatsby-link';
import URL_PATH from '../../UrlPath';
import FabWrapper from '../../components/FabWrapper';
import ProductItem from '../../components/ProductItem';
import SideNav from './SideNav';
import * as ProductUtil from '../../domain/Product';
import useDimension from '../../hooks/use-dimension';
import * as AppContext from '../../AppContext';
import imgDefaultCatBanner from '../../images/default-cat-banner.png';
import {CATEGORY_ALL_PRODUCTS_KEY} from '../../domain/Constants';
import queryParams from '../../utils/queryParams';
import {findCatInTree} from '../../utils/product';
import {Input, Result} from 'antd';
import {updateQueries} from '../../utils/updateQueries';
import Gtag, {useViewed} from '../../utils/gtag';
const {Search} = Input;

const PAGE_SIZE = 20;
const ProductListPage = (props) => {
  const app = React.useContext(AppContext.Context);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [searchKeywordLoading, setSearchKeywordLoading] = useState(false);
  const {dimension, tablet, mobile} = useDimension();
  const products = app.products;
  const dataInUrl = React.useMemo(() => queryParams(props.location.search), [
    props.location.search,
  ]);
  const uiCatTree = React.useMemo(
    () => ProductUtil.convertCatDataToUiCat(app.categoryTree),
    [app.categoryTree],
  );
  const filterCatKey = React.useMemo(() => {
    const _defaultFilterKey = CATEGORY_ALL_PRODUCTS_KEY;
    if (!uiCatTree) {
      return _defaultFilterKey;
    }
    const _targetCatName = dataInUrl.cat;
    const _foundCat =
      _targetCatName && findCatInTree(uiCatTree, _targetCatName, true);
    if (_foundCat) {
      return _foundCat.key;
    }
    return _defaultFilterKey;
  }, [dataInUrl.cat, uiCatTree]);

  function updateRoute(updatedParams) {
    let _dataInUrl = queryParams(props.location.search);
    const nextQueryString = updateQueries({
      keys: ['cat', 'sort', 'search', 'page'],
      params: {..._dataInUrl},
      options: updatedParams,
    });
    navigate(`${URL_PATH.productList}${nextQueryString}`);
  }
  let currPage = Number(dataInUrl.page) || 1;

  let productOffset = dataInUrl.page ? (dataInUrl.page - 1) * PAGE_SIZE : 0;
  let pageInUrl = dataInUrl.page || 1;
  let searchInUrl = dataInUrl.search || '';
  let catInUrl = dataInUrl.cat || '';

  const _onItemClick = React.useCallback(async (item) => {
    Gtag('event', 'select_item', {
      item_list_name: item.category?.slice(-1)[0] || 'all',
      items: [
        {
          item_id: item.id,
          item_name: item.name,
        },
      ],
    });
    await navigate(`${URL_PATH.product}/?id=${item.id}`);
  }, []);

  const _onCatClick = React.useCallback(
    (cat) => {
      updateRoute({cat: cat.name, page: 1, search: ''});
    },
    [updateRoute],
  );

  React.useEffect(() => {
    // sync UI from URL
    // TODO: (enhancement) verified for available category / paging, if not exist, then should navigate to first page or default category
    updateRoute({page: pageInUrl, search: searchInUrl, cat: catInUrl});
  }, [pageInUrl, searchInUrl, catInUrl]);

  React.useEffect(() => {
    const _targetCatName = dataInUrl.cat;
    // if no cat in data url, set active cat to first cat which has 2 layers -> rollback to first 1 layer -> if none of cat exists then do nothing
    const _findDefaultCat = (rootCat) => {
      if (!Array.isArray(rootCat.children) || rootCat.children.length === 0) {
        return null;
      }

      let found = rootCat.children.find(
        (child) => child.children && child.children.length !== 0,
      );
      if (!found) {
        found = rootCat.children[0];
      }

      return found;
    };

    if (uiCatTree && !_targetCatName) {
      let _defaultCat = _findDefaultCat(uiCatTree) || null;
      if (_defaultCat) {
        updateRoute({cat: _defaultCat.name});
      }
    }
  }, [dataInUrl.cat, uiCatTree]);

  const _breakcrumbs = React.useMemo(() => {
    let result = [];
    if (!app.categoryTree || !filterCatKey) {
      // do not push ALL to breadcrumbs
      // result.push({name: '全部商品', key: CATEGORY_ALL_PRODUCTS_KEY});
      return result;
    }
    let cats = filterCatKey.split('__');
    let _fullKeyCats = cats.map((c, idx, arr) => {
      let _catFullName = '';
      let _curIdx = 0;
      while (_curIdx <= idx) {
        _catFullName = `${_catFullName}${_curIdx === 0 ? '' : '__'}${
          arr[_curIdx]
        }`;
        _curIdx++;
      }

      return _catFullName;
    });

    for (let cat of _fullKeyCats) {
      let foundCat = findCatInTree(uiCatTree, cat);
      if (foundCat.key !== CATEGORY_ALL_PRODUCTS_KEY) {
        // do not push ALL to breadcrumbs
        result.push({name: foundCat.name, key: foundCat.key});
      }
    }

    return result;
  }, [app.categoryTree, filterCatKey, uiCatTree]);

  const _curActiveCat = React.useMemo(() => {
    if (!app.categoryTree || !filterCatKey) {
      return null;
    }
    let foundCat = findCatInTree(uiCatTree, filterCatKey);
    return foundCat;
  }, [app.categoryTree, filterCatKey, uiCatTree]);

  const _onSetKeyword = (sc) => {
    setSearchKeywordLoading(true);
    Gtag('event', 'search', {
      search_term: sc,
    });
    updateRoute({search: sc, page: 1});
    setTimeout(() => {
      setSearchKeywordLoading(false);
    }, 500);
  };

  const allAvailableProducts = React.useMemo(() => {
    return products
      .filter(
        (p) =>
          ProductUtil.isNormalProduct(p) ||
          ProductUtil.isValidAdditionalProduct(p),
      )
      .filter(ProductUtil.isProductAllowToBuy)
      .filter(ProductUtil.isPublishedProduct)
      .map((p) => ProductUtil.convertProductCatToUiCat(p, app.categoryTree))
      .filter((p) =>
        filterCatKey
          ? JSON.stringify(p.category).indexOf(filterCatKey) !== -1
          : true,
      )
      .slice()
      .sort((a, b) => b.updated - a.updated)
      .filter((p) =>
        dataInUrl.search ? p.name.indexOf(dataInUrl.search) > -1 : true,
      );
  }, [
    products,
    productOffset,
    filterCatKey,
    dataInUrl.search,
    app.categoryTree,
  ]);

  const displayProducts = React.useMemo(() => {
    return allAvailableProducts.slice(productOffset, productOffset + PAGE_SIZE);
  }, [allAvailableProducts, productOffset]);

  useEffect(() => {
    if (displayProducts.length <= 0) {
      return;
    }
    Gtag('event', 'view_item_list', {
      item_list_name: filterCatKey,
      items: displayProducts.map((p) => ({
        item_id: p.id,
        item_name: p.name,
      })),
    });
  }, [displayProducts, filterCatKey]);

  let totalPages = Math.ceil(allAvailableProducts.length / PAGE_SIZE);

  return (
    <Container catBannerUrl={_curActiveCat?.image || imgDefaultCatBanner}>
      <div className="placeholder" style={{backgroundColor: '#fff'}} />
      <SideNav
        tablet={tablet}
        visible={isSideNavOpen}
        activeCat={filterCatKey}
        onCatClick={(cat) => {
          _onCatClick(cat);
          if (!Array.isArray(cat.children)) {
            setIsSideNavOpen(false);
          }
        }}
        catTree={uiCatTree}
      />
      <section className="content">
        <div className={'cat-banner'} alt="banner" />
        <section className="header">
          <section className="breadcrumbs">
            {_breakcrumbs.map((b, idx) => (
              <div
                className="breadcrumb"
                key={idx}
                onClick={() => _onCatClick(b)}>
                {b.name}
              </div>
            ))}
          </section>
          <section className="action">
            <Search
              loading={searchKeywordLoading}
              className="product-search"
              placeholder="請輸入關鍵字"
              allowClear
              enterButton="搜尋"
              size="middle"
              onSearch={_onSetKeyword}
            />
          </section>
        </section>
        <div className="content-product-list">
          {displayProducts.length === 0 && (
            <div className="empty-product-wrapper">
              <Result
                title="無符合的商品"
                subTitle={'請嘗試搜尋其他關鍵字或類別'}
                status="info"
              />
            </div>
          )}
          {displayProducts.map((p, idx) => (
            <ProductItem
              key={idx}
              item={p}
              onItemClick={_onItemClick}
              style={{margin: '35px 0'}}
            />
          ))}
          {[1, 2, 3].map((idx) => (
            <div key={idx} className="filler" />
          ))}
        </div>
        <div className="paging-row">
          <button
            disabled={currPage <= 1}
            onClick={() => updateRoute({page: currPage - 1})}>{`<`}</button>
          <div style={{margin: 10}}>{`${currPage} of ${totalPages}`}</div>
          <button
            disabled={currPage === totalPages}
            onClick={() => updateRoute({page: currPage + 1})}>{`>`}</button>
        </div>
      </section>
      <div className="placeholder" />
      <FabWrapper
        position="top-right"
        isShown={tablet}
        onClick={() => {
          setIsSideNavOpen(!isSideNavOpen);
        }}>
        {isSideNavOpen ? (
          <CloseOutlined className="icon" />
        ) : (
          <UnorderedListOutlined className="icon" />
        )}
      </FabWrapper>
    </Container>
  );
};

const Container = styled.div`
  --side-nav-width: 300px;
  background-color: #fffbf0;
  flex: 1;
  display: flex;
  position: relative;
  & > .placeholder {
    width: calc((100vw - var(--content-max-width)) / 2);
  }
  & > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px var(--content-padding) 0 var(--content-padding);
    & > .cat-banner {
      width: 100%;
      aspect-ratio: 970 / 369;
      border-radius: 10px;
      margin-bottom: 40px;
      background-image: ${(props) => `url("${props.catBannerUrl}")`};
      background-size: cover;
      background-position: center;
    }
    & > section.header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > .breadcrumbs {
        display: flex;
        align-items: center;

        & > .breadcrumb {
          font-size: 14px;
          color: #808080;
          font-weight: 600;
          cursor: pointer;

          &:not(:last-child)::after {
            content: '/';
            margin-right: 5px;
            margin-left: 5px;
          }
        }
      }
      & > .action {
        display: flex;
        & > .product-search {
          max-width: 300px;
        }
      }
    }
    & > .content-product-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & > .filler {
        width: calc(100% / 3 - 35px);
        height: 1px;
        background-color: transparent;
        visibility: hidden;
        opacity: 0;
      }

      & > .empty-product-wrapper {
        width: 100%;
        margin-top: 20px;
      }
    }
    & > .paging-row {
      display: flex;
      justify-content: center;
      & > button {
        background: none;
        border: none;
        padding: 10px;
        cursor: pointer;
      }
      button[disabled] {
        color: #ccc;
        cursor: not-allowed;
      }
    }
  }
  @media screen and (max-width: 992px) {
    & > .content {
      & > section.header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        & > .breadcrumbs {
          & > .breadcrumb {
            &:not(:last-child)::after {
            }
          }
        }
        & > .action {
          margin-top: 30px;
          align-self: center;
        }
      }
      & > .content-product-list {
        & > .filler {
          width: calc(100% / 2 - 10px);
        }
      }
    }
  }
`;

export default ProductListPage;
