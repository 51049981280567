function findCatInTree(tree, targetKey, findByName = false) {
  let found = null;
  let curCat = tree;

  if (!targetKey) {
    return null;
  }

  if (curCat[findByName ? 'name' : 'key'] === targetKey) {
    return curCat;
  }

  if (curCat.children) {
    for (let cat of curCat.children) {
      let _result = findCatInTree(cat, targetKey, findByName);
      if (_result) {
        found = _result;
        break;
      }
    }
  }

  return found;
}

export {findCatInTree};
