import * as ProductUtil from '../domain/Product';
import React from 'react';
import styled from 'styled-components';
import FixedRatioImage from './FixedRatioImage';

const ProductItem = (props) => {
  const {item, style = {}, onItemClick = () => 0} = props;
  return (
    <Wrapper onClick={() => onItemClick(item)} style={style}>
      <FixedRatioImage
        width="100%"
        mode="cover"
        image={item.image}
        ratio={1}
        style={{overflow: 'hidden'}}
      />
      <section className="detail">
        <div className="info">{item.name}</div>
        {/* <div className="info">
          NT ${ProductUtil.getMinimumProductPrice(item)} 起
        </div> */}
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 35px);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 30px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  & > .detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 0;
    background-color: #fff;
    & > .info {
      margin: 5px 0;
    }
  }
  @media screen and (max-width: 992px) {
    width: calc(100% / 2 - 10px);
    font-size: 12px;
  }

  transition: box-shadow 0.2s ease-in;
  /* style for product item */
  & .fixed-ratio-image {
    transition: transform 0.2s ease-in, opacity 0.2s ease-in;
  }
  &:hover {
    box-shadow: 0 5px 30px 4px rgba(0, 0, 0, 0.15);
    & .fixed-ratio-image {
      transform: scale(1.05);
      opacity: 0.8;
    }
  }
`;

export default ProductItem;
